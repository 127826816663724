import { config } from "../../../config";
import { addEqConditionally, addInConditionally, addOrConditionally, addSkipAndLimit } from "../../../common/lib/postgrest.lib";
import { isNumeric } from "../../../common/lib/misc";

export const userListAPIUrl = (statuses: number[], globalFilter?: string, offset: number = 0, limit: number = 30) => {
    const statusFinal = statuses.length > 0 ? statuses : [ 1 ];
    const url = new URL(`${config.mdmServiceBasePath}/common/jpax_lk_user`);
    const whereUrl = addInConditionally(url, statusFinal, "status");

    const whereWithUserCond =
        globalFilter !== undefined ?
            addOrConditionally(whereUrl, isNumeric(globalFilter) ? [ {
                field: "id",
                value: globalFilter
            } ] : [], [ {
                field: "communication_email",
                value: globalFilter
            } ])
            : whereUrl;

    return addSkipAndLimit(whereWithUserCond, offset, limit);
};

export const usersDetailsAPIUrl = (id: number) => {
    const url = new URL(`${config.mdmServiceBasePath}/dashboard/users_details`);
    return addEqConditionally(url, "id", id.toString());
}

export const usersTicketsAPIUrl = (id: number) => {
    const url = new URL(`${config.mdmServiceBasePath}/dashboard/user_ticket_season_map_with_prices`);
    return addEqConditionally(url, "user_id", id.toString());
}

export const usersViolationsBaseAPIUrl = `${config.liveBackendBasePath}/admin/violations`;

export const usersRolesListAPIUrl = `${config.mdmServiceBasePath}/common/jpax_general_role`;

export const usersEditRolesAPIUrl = `${config.jpaServiceAPIBasePath}/admin/editRole`;

export const deactivateUserAPIUrl=`${config.jpaServiceAPIBasePath}/admin/deactivateUser`;
export const reactivateUserAPIUrl=`${config.jpaServiceAPIBasePath}/admin/reactivateUser`;

export const resetPasswordAPIUrl = `${config.jpaServiceAPIBasePath}/admin/resetPassword`;

export const usersStatsAPIUrl = `${config.mdmServiceBasePath}/dashboard/users_stats`;

