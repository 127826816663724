import { ContactEntity } from "../../models/user-details.models";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { bodyTemplate } from "../../../../../common/components/tables";
import React, { FC } from "react";

interface UserContactsInfoProps {
    data: ContactEntity[];
}

const renderTypeBadge = (data: any, props: any) => {
    const type = data[props.field];
    return (
        <>
            <span className="p-column-title">Type</span>
            <span className={`user-badges type-${type}`}>{type === 0 ? 'EMAIL' : type === 1 ? 'PHONE' : 'UNKNOWN'}</span>
        </>
    )
};

const booleanTemplate = (data: any, props: any) => {
    const status = data[props.field].toString();
    return (
        <>
            <span className="p-column-title">FullPass</span>
            <span className={`user-badges bool-${status}`}>{data[props.field] ? "YES" : "NO"}</span>
        </>
    )
};

const verificationTemplate = (data: any, props: any) => {
    const status = data[props.field];
    return (
        <>
            <span className="p-column-title">FullPass</span>
            <span className={`user-badges bool-${status}`}>{status === 2 ? "YES" : "NO"}</span>
        </>
    )
};

export const UserContactsInfo: FC<UserContactsInfoProps> = ({ data }) => {
    return (
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
            <div className="card overview-box p-d-flex p-flex-column p-py-3">
                <div className="p-d-flex p-flex-row p-py-3 p-ai-center">
                    <div className="p-mx-2">
                        <i className="pi pi-address-book"
                           style={{ fontSize: "2rem" }}
                        />
                    </div>
                    <div className="p-d-flex p-flex-column p-ml-4">
                        <h5>Contacts</h5>
                        <p>Info about user' contacts</p>
                    </div>
                </div>
                <Divider/>
                <div className="p-d-flex p-flex-column datatable-responsive">
                    {
                        data &&
                        <DataTable
                            className="p-datatable-gridlines p-datatable-striped p-datatable-responsive"
                            value={data}
                            dataKey="id"
                            sortField="created_date"
                            sortOrder={1}
                            emptyMessage="No contacts found"
                        >
                            <Column field="id"
                                    sortable
                                    header="Id" headerStyle={{ width: '7rem' }}
                                    body={bodyTemplate}
                            />
                            <Column field="type"
                                    header="Type" headerStyle={{ width: '11rem' }}
                                    body={renderTypeBadge}
                            />
                            <Column field="value"
                                    sortable
                                    header="Value" headerStyle={{ width: '7rem' }}
                                    body={bodyTemplate}
                            />
                            <Column field="verification_status"
                                    body={verificationTemplate}
                                    header="Verified" headerStyle={{ width: '6rem' }}
                            />
                            <Column field="used_by_default"
                                    body={booleanTemplate}
                                    header="Used by default" headerStyle={{ width: '6rem' }}
                            />
                        </DataTable>
                    }
                </div>
            </div>
        </div>
    );
}
